import { mapActions, mapState, mapMutations } from 'vuex'
import last from 'lodash/last'

import thumbImg from '../assets/images/icon-doc-black.svg'

export const uploader = {
  data() {
    return {
      counter: 0,
      files: [],
      filesLength: 0,
      isLoading: false,
      sources: [],
      thumbImg,
    }
  },

  created() {
    if (this.preloadedFiles) {
      this.preloadedFiles.forEach(async (item) => {
        if (!item.key) {
          return
        }

        const data = await this.getS3FileUrl(item.key)

        if (!data?.url) {
          return
        }

        this.files.push({
          id: item.id,
          name: item.key.split('/').slice(-1)[0],
          key: item.key,
          url: data.url,
        })

        this.sources.push({
          src: data.url,
          mediaType: this.getMediaType(item.key),
        })
      })
    }
  },

  computed: mapState(['companyId', 'loading']),

  methods: {
    ...mapMutations(['setError', 'setLoading']),
    ...mapActions(['getSignedUrl', 'getS3FileUrl', 'destroyS3File']),

    onSelect() {
      this.setLoading(true)
      const formData = new FormData()
      const fileList = this.$refs.file.files
      this.counter = 0
      this.filesLength = fileList.length

      Array.from(Array(fileList.length).keys()).forEach(async (item) => {
        const _file = fileList[item]
        const ext = last(_file.name.split('.'))
        const folder = this.folderPrefix
          ? this.folderPrefix + this.companyId + '/'
          : this.companyId + '/'
        const filename = `${this.code}_${Date.now()}.${ext}`

        formData.append('files', _file, filename)

        const data = {
          key: folder + filename,
          contentType: _file.type,
          size: _file.size,
        }

        const signedUploadURL = await this.getSignedUrl(data).catch(
          ({ response }) => {
            const { error = 'internal-error' } = response?.data || {}
            this.setError(error)
          },
        )

        if (signedUploadURL) {
          await this.signedUpload(signedUploadURL?.url, data, _file)
        }
      })
    },

    async signedUpload(url, data, formData) {
      if (!url) return
      this.isLoading = true
      this.setLoading(true)

      const options = {
        method: 'PUT',
        headers: {
          'Access-Control-Allow-Origin': document.location.origin,
          'Content-Type': data.contentType,
          'Content-Length': data.size,
          'x-amz-acl': 'private',
          'x-amz-server-side-encryption': 'AES256',
        },
        body: formData,
        credentials: 'include',
      }

      try {
        const response = await fetch(url, options)

        if (response.status === 200) {
          const fileData = await this.getS3FileUrl(data.key)

          if (!fileData?.url) {
            return
          }

          this.files.push({
            name: formData.name,
            key: data.key,
            url: fileData.url,
            thumb: this.isPDF(data.key) ? thumbImg : fileData.url,
          })

          this.sources.push({
            src: data.url,
            thumb: this.isPDF(data.key) ? thumbImg : fileData.url,
            mediaType: this.getMediaType(data.key),
          })

          this.$emit('file:uploaded', data.key)
          this.counter += 1
        } else {
          this.setError(response.statusText)
        }
      } catch (error) {
        this.setError(error)
      } finally {
        this.isLoading = false
        this.setLoading(false)
      }
    },

    isImage: (ext) => /(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(ext),
    isDoc: (ext) => /(docx?|xlsx?|csv|zip|txt)$/i.test(ext),
    isPDF: (ext) => /(pdf)$/i.test(ext),

    getMediaType(ext) {
      let mediaType = 'image'
      if (this.isDoc(ext) || this.isPDF(ext)) {
        mediaType = 'iframe'
      } else if (/(mp4)$/i.test(ext)) {
        mediaType = 'video'
      } else if (/(mp3)$/i.test(ext)) {
        mediaType = 'iframe'
      }
      return mediaType
    },

    onOpenFile(key) {
      const file = this.files[key]
      if (file && this.isDoc(file.key)) {
        window.open(file.url, '_blank')
      }
    },

    removeFile(index) {
      const { key } = this.files[index]

      if (key) {
        this.destroyS3File(key)
        this.$emit('file:destroyed', key)

        this.files.splice(index, 1)
        this.sources.splice(index, 1)
      }
    },
  },
}
